<template>
  <div style="margin-bottom: -2rem">
    <translate
      v-if="isTranslateMode"
      :translations="translations"
      :translation-active="translationActive"
      :title="name"
      @setTranslationActive="translationActive = $event"
      @setTranslateMode="setTranslateMode"
      @translate="translateAll"
      @clearTranslation="onClearTranslation"
    >
      <template v-slot:default>
        <b-card>
          <b-form-group :label="$t('Item Name')">
            <validation-observer ref="voNameTranslation">
              <validation-provider
                #default="{ errors }"
                name="item_name"
                rules="required"
              >
                <b-form-input
                  v-model="name"
                  :placeholder="$t('Item Name')"
                  :state="errors.length ? false : null"
                  @blur="onBlurName('voNameTranslation')"
                />
              </validation-provider>
            </validation-observer>
          </b-form-group>
          <template v-if="badges.length">
            <b-form-group
              v-for="(badge, badgeIndex) in badges"
              :key="badge.id"
              :label="badgeIndex === 0 ? $t('Tags') : null"
            >
              <b-form-input
                :value="badge.name"
                disabled
              />
            </b-form-group>
          </template>
          <template v-if="tags.length">
            <b-form-group
              v-for="(tag, tagIndex) in tags"
              :key="tag.id"
              :label="tagIndex === 0 ? $t('Tags') : null"
            >
              <b-form-input
                :value="tag.name"
                disabled
              />
            </b-form-group>
          </template>
          <b-form-group :label="$t('Short Description')">
            <validation-observer ref="voDescriptionTranslation">
              <validation-provider
                #default="{ errors }"
                name="short_description"
                :rules="`max:${textLimitShort}`"
              >
                <b-form-textarea
                  v-model="description"
                  :placeholder="$t('Short Description')"
                  rows="3"
                  no-resize
                  :state="errors.length ? false : null"
                  @blur="onBlurDescription('voDescriptionTranslation')"
                />
              </validation-provider>
            </validation-observer>
          </b-form-group>
          <b-form-group :label="$t('Full Description')">
            <validation-observer ref="voLongDescriptionTranslation">
              <validation-provider
                #default="{ errors }"
                name="full_description"
                :rules="`max:${textLimitFull}`"
              >
                <b-form-textarea
                  v-model="longDescription"
                  :placeholder="$t('Full Description')"
                  rows="3"
                  no-resize
                  :state="errors.length ? false : null"
                  @blur="onBlurLongDescription('voLongDescriptionTranslation')"
                />
              </validation-provider>
            </validation-observer>
          </b-form-group>
        </b-card>
        <b-card-actions
          v-if="options.length"
          :title="$t('Customize With Options')"
          action-collapse
        >
          <validation-observer ref="voOptionsTranslation">
            <b-overlay
              v-for="(option, optionIndex) in options"
              :key="option.id"
              :show="isOptionPosting"
              spinner-variant="primary"
            >
              <b-card-actions
                class="card-inner"
                :style="{ marginBottom: options.length === optionIndex + 1 ? '-2rem' : null }"
                :title="option.name ? option.name : $t('Options Set')"
                action-collapse
              >
                <b-row>
                  <b-col>
                    <b-form-group
                      :label="$t('Option Set Title')"
                      class="mb-0"
                    />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group class="mb-0">
                      <validation-provider
                        #default="{ errors }"
                        name="option_set_title"
                        :vid="option.id"
                        :rules="`max:${textLimitOption}`"
                      >
                        <b-form-input
                          v-model="option.name"
                          :placeholder="$t('Option Set Title')"
                          :disabled="!isOptionsValid"
                          :state="errors.length ? false : null"
                          @blur="onBlurOptionName('voOptionsTranslation')"
                        />
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row
                  v-if="option.items.length > 1"
                  class="mt-2 pt-50"
                >
                  <b-col>
                    <b-form-group
                      :label="$t('List of Options')"
                      class="mb-0"
                    />
                  </b-col>
                </b-row>
                <b-row
                  v-for="(item, itemIndex) in option.items.filter((i, iIndex) => iIndex + 1 !== option.items.length)"
                  :key="item.id"
                >
                  <b-col>
                    <b-form-group :class="{'mb-0': option.items.length === itemIndex + 2}">
                      <b-form-input
                        v-model="item.name"
                        :placeholder="$t('e.g. Small, Medium, Large')"
                        :disabled="!isOptionsValid"
                        @blur="onBlurOptionItemName('voOptionsTranslation')"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card-actions>
            </b-overlay>
          </validation-observer>
        </b-card-actions>
        <b-card-actions
          v-if="extras.length"
          :title="$t('Up-Sell With Extras')"
          action-collapse
        >
          <validation-observer ref="voExtrasTranslation">
            <b-overlay
              v-for="(extra, extraIndex) in extras"
              :key="extra.id"
              :show="isExtraPosting"
              spinner-variant="primary"
            >
              <b-card-actions
                class="card-inner"
                :style="{ marginBottom: extras.length === extraIndex + 1 ? '-2rem' : '' }"
                :title="extra.name ? extra.name : $t('Extras Set')"
                action-collapse
              >
                <b-row>
                  <b-col>
                    <b-form-group
                      :label="$t('Extra Name')"
                      class="mb-0"
                    />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group class="mb-0">
                      <validation-provider
                        #default="{ errors }"
                        name="extra_name"
                        :vid="extra.id"
                        :rules="`max:${textLimitExtra}`"
                      >
                        <b-form-input
                          v-model="extra.name"
                          :placeholder="$t('Extra Name')"
                          :disabled="!isExtrasValid"
                          :state="errors.length ? false : null"
                          @blur="onBlurExtraName('voExtrasTranslation')"
                        />
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row
                  v-if="extra.items.filter(i => i.type === 'SimpleItem').length"
                  class="mt-2 pt-50"
                >
                  <b-col>
                    <b-form-group
                      :label="$t('List of Extras')"
                      class="mb-0"
                    />
                  </b-col>
                </b-row>
                <b-row
                  v-for="(item, index) in extra.items.filter(i => i.type === 'SimpleItem')"
                  :key="item.id"
                >
                  <b-col>
                    <b-form-group :class="{'mb-0': extra.items.filter(i => i.type === 'SimpleItem').length === index + 1}">
                      <validation-provider
                        #default="{ errors }"
                        name="name"
                        :vid="item.id"
                        rules="required"
                      >
                        <b-form-input
                          :placeholder="$t('Name')"
                          :state="errors.length ? false : null"
                          :disabled="!isExtrasValid"
                          :value="item.name"
                          @input="onInputExtraItemParam($event, 'name', extra.id, item.id, 'voExtrasTranslation')"
                          @blur="onBlurExtraSimpleItemName('voExtrasTranslation')"
                        />
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card-actions>
            </b-overlay>
          </validation-observer>
        </b-card-actions>
        <b-card-actions
          :title="$t('cardSuggestionsTitle')"
          action-collapse
        >
          <b-form-group
            :label="$t('cardSuggestionsNameLabel')"
            class="mb-0"
          >
            <validation-observer ref="suggestionsName">
              <validation-provider
                #default="{ errors }"
                name="suggestions_name"
                :rules="`max:${textLimitSuggestions}`"
              >
                <b-form-input
                  v-model="suggestionName"
                  :placeholder="$t('cardSuggestionsNamePlaceholder')"
                  :state="errors.length ? false : null"
                  @blur="onBlurSuggestionName"
                />
              </validation-provider>
            </validation-observer>
          </b-form-group>
        </b-card-actions>
      </template>
      <template v-slot:translation="{ translation }">
        <b-card>
          <b-form-group :label="$t('Item Name')">
            <b-input-group class="input-group-merge">
              <b-form-input
                v-model="translation.name"
                :disabled="isSubscriptionInactiveOrBasic"
                :placeholder="$t('Item Name')"
                @blur="patchTranslationActive"
              />
              <b-input-group-append
                v-if="isSubscriptionPremium"
                is-text
              >
                <icon-translate
                  v-b-tooltip.hover.top="$t('Auto Translate')"
                  class="size-18"
                  role="button"
                  @click.native="onClickTranslateField('name')"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
          <item-badges-tags-translations
            ref="item-badges-tags-translations_badges"
            :translation-active="translationActive"
            :value="badges"
            :label="`${$t('Badge')}`"
            :items="badgesFromStore"
            :items-translations="badgesTranslations"
            :set-items-translations="setBadgesTranslations"
            :update-item-translation="updateBadgeTranslation"
          />
          <item-badges-tags-translations
            ref="item-badges-tags-translations_tags"
            :translation-active="translationActive"
            :value="tags"
            :label="`${$t('Tags')}`"
            :items="tagsFromStore"
            :items-translations="tagsTranslations"
            :set-items-translations="setTagsTranslations"
            :update-item-translation="updateTagTranslation"
          />
          <b-form-group :label="$t('Short Description')">
            <b-input-group class="input-group-merge">
              <b-form-textarea
                v-model="translation.description"
                :disabled="isSubscriptionInactiveOrBasic"
                :placeholder="$t('Short Description')"
                rows="3"
                no-resize
                @blur="patchTranslationActive"
              />
              <b-input-group-append
                v-if="isSubscriptionPremium"
                is-text
              >
                <icon-translate
                  v-b-tooltip.hover.top="$t('Auto Translate')"
                  class="size-18"
                  role="button"
                  @click.native="onClickTranslateField('description')"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
          <b-form-group :label="$t('Full Description')">
            <b-input-group class="input-group-merge">
              <b-form-textarea
                v-model="translation.longDescription"
                :disabled="isSubscriptionInactiveOrBasic"
                :placeholder="$t('Full Description')"
                rows="3"
                no-resize
                @blur="patchTranslationActive"
              />
              <b-input-group-append
                v-if="isSubscriptionPremium"
                is-text
              >
                <icon-translate
                  v-b-tooltip.hover.top="$t('Auto Translate')"
                  class="size-18"
                  role="button"
                  @click.native="onClickTranslateField('longDescription')"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-card>
        <b-card-actions
          v-if="translation.options && translation.options.length"
          :title="$t('Customize With Options')"
          action-collapse
        >
          <b-card-actions
            v-for="(option, optionIndex) in translation.options"
            :key="translation.language + '_' + option.id"
            class="card-inner"
            :style="{ marginBottom: translation.options.length === optionIndex + 1 ? '-2rem' : null }"
            :title="option.name ? option.name : $t('Options Set')"
            action-collapse
          >
            <b-row>
              <b-col>
                <b-form-group
                  :label="$t('Option Set Title')"
                  class="mb-0"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group class="mb-0">
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      v-model="option.name"
                      :placeholder="$t('Option Set Title')"
                      :disabled="isSubscriptionInactiveOrBasic"
                      @blur="patchTranslationActive"
                    />
                    <b-input-group-append
                      v-if="isSubscriptionPremium"
                      is-text
                    >
                      <icon-translate
                        v-b-tooltip.hover.top="$t('Auto Translate')"
                        class="size-18"
                        role="button"
                        @click.native="onClickTranslateField(`options[${optionIndex}].name`)"
                      />
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row
              v-if="option.items.length > 1"
              class="mt-2 pt-50"
            >
              <b-col>
                <b-form-group
                  :label="$t('List of Options')"
                  class="mb-0"
                />
              </b-col>
            </b-row>
            <b-row
              v-for="(item, itemIndex) in option.items.filter((i, iIndex) => iIndex + 1 !== option.items.length)"
              :key="translation.language + '_' + item.id"
            >
              <b-col>
                <b-form-group :class="{'mb-0': option.items.length === itemIndex + 2}">
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      v-model="item.name"
                      :placeholder="$t('e.g. Small, Medium, Large')"
                      :disabled="isSubscriptionInactiveOrBasic"
                      @blur="patchTranslationActive"
                    />
                    <b-input-group-append
                      v-if="isSubscriptionPremium"
                      is-text
                    >
                      <icon-translate
                        v-b-tooltip.hover.top="$t('Auto Translate')"
                        class="size-18"
                        role="button"
                        @click.native="onClickTranslateItem('options', option.id, item.id)"
                      />
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-actions>
        </b-card-actions>
        <b-card-actions
          v-if="translation.extras && translation.extras.length"
          :title="$t('Up-Sell With Extras')"
          action-collapse
        >
          <b-card-actions
            v-for="(extra, extraIndex) in translation.extras"
            :key="translation.language + '_' + extra.id"
            class="card-inner"
            :style="{ marginBottom: translation.extras.length === extraIndex + 1 ? '-2rem' : '' }"
            :title="extra.name ? extra.name : $t('Extras Set')"
            action-collapse
          >
            <b-row>
              <b-col>
                <b-form-group
                  :label="$t('Extra Name')"
                  class="mb-0"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group class="mb-0">
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      v-model="extra.name"
                      :placeholder="$t('Extra Name')"
                      :disabled="isSubscriptionInactiveOrBasic"
                      @blur="patchTranslationActive"
                    />
                    <b-input-group-append
                      v-if="isSubscriptionPremium"
                      is-text
                    >
                      <icon-translate
                        v-b-tooltip.hover.top="$t('Auto Translate')"
                        class="size-18"
                        role="button"
                        @click.native="onClickTranslateField(`extras[${extraIndex}].name`)"
                      />
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row
              v-if="extra.items.length"
              class="mt-2 pt-50"
            >
              <b-col>
                <b-form-group
                  :label="$t('List of Extras')"
                  class="mb-0"
                />
              </b-col>
            </b-row>
            <b-row
              v-for="(item, itemIndex) in extra.items"
              :key="translation.language + '_' + item.id"
            >
              <b-col>
                <b-form-group :class="{'mb-0': extra.items.length === itemIndex + 1}">
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      v-model="item.name"
                      :placeholder="$t('Name')"
                      :disabled="isSubscriptionInactiveOrBasic"
                      @blur="patchTranslationActive"
                    />
                    <b-input-group-append
                      v-if="isSubscriptionPremium"
                      is-text
                    >
                      <icon-translate
                        v-b-tooltip.hover.top="$t('Auto Translate')"
                        class="size-18"
                        role="button"
                        @click.native="onClickTranslateItem('extras', extra.id, item.id)"
                      />
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-actions>
        </b-card-actions>
        <b-card-actions
          :title="$t('cardSuggestionsTitle')"
          action-collapse
        >
          <b-form-group
            :label="$t('cardSuggestionsNameLabel')"
            class="mb-0"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                v-model="translation.suggestionName"
                :disabled="isSubscriptionInactiveOrBasic"
                :placeholder="$t('cardSuggestionsNamePlaceholder')"
                @blur="patchTranslationActive"
              />
              <b-input-group-append
                v-if="isSubscriptionPremium"
                is-text
              >
                <icon-translate
                  v-b-tooltip.hover.top="$t('Auto Translate')"
                  class="size-18"
                  role="button"
                  @click.native="onClickTranslateField('suggestionName')"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-card-actions>
      </template>
    </translate>
    <template v-else>
      <b-overlay
        :show="isFetching"
        spinner-variant="primary"
      >
        <b-card
          no-body
          class="pt-2"
        >
          <div class="pl-2 pr-2">
            <b-row>
              <b-col />
              <b-col
                cols="auto"
                class="d-flex"
              >
                <icon-stop-list
                  v-b-tooltip.hover.top="$t('Temporary Unavailable')"
                  :class="['mr-2 size-20', {'fill-danger': !isEnabled}]"
                  role="button"
                  @click.native="onClickEnabled"
                />
                <feather-icon
                  v-b-tooltip.hover.top="$t('Hide in Menus')"
                  class="mr-2"
                  :icon="isVisible ? 'EyeIcon' : 'EyeOffIcon'"
                  role="button"
                  size="20"
                  @click="onClickVisible"
                />
                <icon-preview
                  v-b-tooltip.hover.top="$t('Preview')"
                  role="button"
                  class="size-20 mr-2"
                  :style="{
                    opacity: offers.length ? 1 : 0.5,
                    cursor: offers.length ? 'pointer' : 'default',
                  }"
                  @click.native="openPreviewModal"
                />
                <icon-translate
                  v-b-tooltip.hover.top="$t('Translate')"
                  role="button"
                  class="size-20"
                  @click.native="setTranslateMode(true)"
                />
                <b-spinner
                  v-if="isDuplicating"
                  variant="primary"
                  class="spinner-20 ml-2"
                />
                <feather-icon
                  v-else
                  v-b-tooltip.hover.top="$t('Duplicate')"
                  class="ml-2"
                  icon="CopyIcon"
                  role="button"
                  size="20"
                  @click="onClickDuplicate"
                />
                <feather-icon
                  v-b-tooltip.hover.top="$t('Delete')"
                  class="ml-2 text-danger"
                  icon="TrashIcon"
                  size="20"
                  role="button"
                  @click="isModalConfirmDelete = true"
                />
              </b-col>
            </b-row>
            <name-title
              class="mt-2"
              :value="name"
              :is-card-content="isCardContent"
              :placeholder="`${$t('Item Name')}`"
              :failed-rule-required="`${$t('validationErrorRequiredItemName')}`"
              validation-provider-name="item_name"
              @save="onSaveName"
              @collapse="isCardContent = !isCardContent"
            />
          </div>
          <b-collapse v-model="isCardContent">
            <div class="d-flex flex-wrap border-top">
              <item-badges-tags
                class="w-50"
                icon="AwardIcon"
                :title="`${$t('Badge')}`"
                :tooltip-text="`${$t('badgeNewPlaceholder')}`"
                validation-error-key-items="validationErrorMaxBadges"
                validation-error-key-item="validationErrorMaxBadge"
                menu-item-key="badges"
                badge-selected-variant="outline-primary"
                :is-clear-button="false"
                :value-max-length="1"
                :text-limit="15"
                :items="badgesFromStore"
                :items-translations="badgesTranslations"
                :set-items="setBadges"
                :set-items-translations="setBadgesTranslations"
                :delete-item="deleteBadge"
                :delete-item-translation="deleteBadgeTranslation"
                :create-item="createBadge"
                :create-item-translation="createBadgeTranslation"
                :value="badges"
                @input="onInputBadges"
              />
              <item-preparation
                v-model="preparation"
                class="w-50"
                @savePreparationTime="onSavePreparationTime"
                @savePreparationIsInMenus="onSavePreparationIsInMenus"
              />
            </div>
            <item-badges-tags
              icon="TagIcon"
              :title="`${$t('Tags')}`"
              :tooltip-text="`${$t('infoTags')}`"
              validation-error-key-items="validationErrorMaxTags"
              validation-error-key-item="validationErrorMaxTag"
              menu-item-key="tags"
              badge-selected-variant="outline-secondary"
              :is-clear-button="true"
              :value-max-length="5"
              :text-limit="25"
              :items="tagsFromStore"
              :items-translations="tagsTranslations"
              :set-items="setTags"
              :set-items-translations="setTagsTranslations"
              :delete-item="deleteTag"
              :delete-item-translation="deleteTagTranslation"
              :create-item="createTag"
              :create-item-translation="createTagTranslation"
              :value="tags"
              @input="onInputTags"
            />
            <b-form-group
              class="pl-2 pr-2 mt-50"
              :label="$t('labelAllergens')"
            >
              <treeselect
                v-model="allergens"
                :options="optionsAllergens"
                :placeholder="$t('placeholderAllergens')"
                :normalizer="allergensNormalizer"
                :multiple="true"
                :clearable="false"
                :flat="true"
                class="vue-treeselect_allergens"
                @close="onCloseTreeselectAllergens"
              />
            </b-form-group>
            <item-group :title="`${$t('Description')}`">
              <b-form-group :label="$t('Short Description')">
                <validation-observer ref="voDescription">
                  <validation-provider
                    #default="{ errors }"
                    name="short_description"
                    :rules="`max:${textLimitShort}`"
                  >
                    <textarea-counter
                      size=""
                      :disabled="false"
                      :placeholder="`${$t('Short Description')}`"
                      :is-error="!!errors.length"
                      :text-limit="textLimitShort"
                      :value="description"
                      @input="description = $event"
                      @blur="onBlurDescription('voDescription')"
                    />
                  </validation-provider>
                </validation-observer>
              </b-form-group>
              <b-form-group
                :label="$t('Full Description')"
                class="mb-0"
              >
                <validation-observer ref="voLongDescription">
                  <validation-provider
                    #default="{ errors }"
                    name="full_description"
                    :rules="`max:${textLimitFull}`"
                  >
                    <textarea-counter
                      size=""
                      :disabled="false"
                      :placeholder="`${$t('Full Description')}`"
                      :is-error="!!errors.length"
                      :text-limit="textLimitFull"
                      :value="longDescription"
                      @input="longDescription = $event"
                      @blur="onBlurLongDescription('voLongDescription')"
                    />
                  </validation-provider>
                </validation-observer>
              </b-form-group>
            </item-group>
            <item-videos-images
              class="pb-75"
              page="item"
              :videos="videos"
              :images="images"
              @input:videos="onInputYoutubeLinks"
              @input:images="onInputImages"
            />
          </b-collapse>
        </b-card>
      </b-overlay>
      <card-pricing
        v-model="offers"
        :is-fetching="isFetching"
        @reset="onResetOffers"
      />
      <b-overlay
        :show="isFetching"
        spinner-variant="primary"
      >
        <b-card-actions
          ref="card-options"
          :title="$t('Customize With Options')"
          action-collapse
          collapsed
        >
          <validation-observer ref="voOptions">
            <draggable
              :list="options"
              @change="onChangeDraggableOption"
            >
              <b-overlay
                v-for="option in options"
                :key="option.id"
                :show="isOptionPosting"
                spinner-variant="primary"
              >
                <b-card-actions
                  class="card-inner"
                  no-body
                  action-collapse
                >
                  <template v-slot:title-and-subtitle>
                    <div
                      class="d-flex align-items-center"
                      style="width: calc(100% - (54px + 20px))"
                    >
                      <icon-move-vertical class="mr-1" />
                      <option-extra-name :value="option.name">
                        <validation-provider
                          #default="{ errors, failedRules }"
                          name="option_set_title"
                          :vid="option.id"
                          :rules="`max:${textLimitOption}`"
                        >
                          <b-form-input
                            v-model="option.name"
                            :placeholder="$t('Option Set Title')"
                            class="input-h input-h5"
                            :state="errors.length ? false : null"
                            @click.stop
                            @blur="onBlurOptionName('voOptions')"
                          />
                          <small class="text-danger">
                            <template v-if="failedRules.max">{{ $t('validationErrorMaxOptionName', { slot: textLimitOption }) }}</template>
                            <template v-else>{{ errors[0] }}</template>
                          </small>
                        </validation-provider>
                      </option-extra-name>
                    </div>
                  </template>
                  <template v-slot:pre-actions>
                    <feather-icon
                      icon="TrashIcon"
                      size="20"
                      :class="['mr-1', isOptionsValid ? 'text-danger' : null]"
                      role="button"
                      :style="{ opacity: isOptionsValid ? 1 : 0.5 }"
                      @click.stop="onClickDeleteOption(option.id)"
                    />
                  </template>
                  <template v-slot:default>
                    <b-card-body>
                      <div class="d-flex mb-2">
                        <b-form-radio
                          v-model="option.type"
                          value="choose"
                          class="mr-2"
                          @change="onChangeOptionType(option, 'choose')"
                        >
                          {{ $t('Choose') }}
                        </b-form-radio>
                        <b-form-radio
                          v-model="option.type"
                          value="add"
                          class="mr-2"
                          @change="onChangeOptionType(option, 'add')"
                        >
                          {{ $t('Add') }}
                        </b-form-radio>
                        <b-form-radio
                          v-model="option.type"
                          value="remove"
                          @change="onChangeOptionType(option, 'remove')"
                        >
                          {{ $t('Remove') }}
                        </b-form-radio>
                      </div>
                      <draggable
                        :list="option.items"
                        :move="checkMoveDraggableOptionItem"
                        @change="onChangeDraggableOptionItem($event, option.id)"
                      >
                        <b-row
                          v-for="(item, itemIndex) in option.items"
                          :key="item.id"
                          :class="['option-item-row', { 'mb-50': itemIndex + 1 !== option.items.length }]"
                        >
                          <b-col class="d-flex align-items-center">
                            <icon-move-vertical class="mr-1" />
                            <b-input-group class="input-group-merge">
                              <b-form-input
                                v-model="item.name"
                                :placeholder="$t('e.g. Small, Medium, Large')"
                                :disabled="itemIndex + 1 === option.items.length && !isOptionsValid"
                                @blur="onBlurOptionItemName('voOptions')"
                              />
                              <b-input-group-append
                                v-if="option.type === 'choose' && itemIndex + 1 !== option.items.length"
                                is-text
                              >
                                <b-form-radio
                                  v-b-tooltip.hover.top="$t('tooltipOptionDefault')"
                                  :checked="option.items.find(i => i.default) ? option.items.find(i => i.default).id : null"
                                  :value="item.id"
                                  :disabled="!item.isEnabled || !item.isVisible"
                                  @change="onChangeOptionItemDefault($event, option.id)"
                                />
                              </b-input-group-append>
                            </b-input-group>
                          </b-col>
                          <b-col
                            cols="auto"
                            class="d-flex mr-2"
                          >
                            <b-form-group
                              v-if="option.type !== 'remove'"
                              class="mb-0 mr-1"
                            >
                              <div class="d-inline-flex mr-50">
                                + {{ currencySymbol }}
                              </div>
                              <validation-provider
                                #default="{ errors }"
                                name="price"
                                :vid="item.id"
                                rules="integer_double"
                                class="d-inline-flex flex-column"
                              >
                                <b-form-input
                                  placeholder="0.00"
                                  :state="errors.length ? false : null"
                                  style="width: 110px"
                                  :disabled="itemIndex + 1 === option.items.length"
                                  :value="item.addPrice"
                                  @input="onInputOptionItemPrice($event, option.id, item.id)"
                                  @blur="onBlurOptionItemPrice"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                            <div
                              class="d-flex"
                              style="padding-top: 9px"
                            >
                              <icon-stop-list
                                v-b-tooltip.hover.top="$t('Display as unavailable')"
                                :class="[
                                  'd-flex size-20',
                                  { 'fill-danger': !item.isEnabled },
                                  { 'disabled': (option.type === 'choose' && item.default)
                                    || !item.isVisible
                                    || option.items.length === itemIndex + 1,
                                  },
                                ]"
                                role="button"
                                @click.native="onClickOptionItemParam('isEnabled', option.id, item.id)"
                              />
                              <feather-icon
                                v-b-tooltip.hover.top="$t('Show in menus')"
                                :icon="item.isVisible ? 'EyeIcon' : 'EyeOffIcon'"
                                size="20"
                                :class="[
                                  'ml-1 mr-1',
                                  { 'feather-disabled': (option.type === 'choose' && item.default)
                                    || option.items.length === itemIndex + 1,
                                  },
                                ]"
                                role="button"
                                @click="onClickOptionItemParam('isVisible', option.id, item.id)"
                              />
                              <feather-icon
                                icon="XCircleIcon"
                                size="20"
                                role="button"
                                :class="[
                                  { 'feather-disabled': option.items.length === itemIndex + 1 || !isOptionsValid },
                                ]"
                                @click="onClickDeleteOptionItem(option.id, item.id)"
                              />
                            </div>
                          </b-col>
                        </b-row>
                      </draggable>
                    </b-card-body>
                  </template>
                </b-card-actions>
              </b-overlay>
            </draggable>
          </validation-observer>
          <b-button
            size="sm"
            variant="outline-primary"
            :disabled="isOptionPosting || !isOptionsValid"
            @click="onClickAddOption"
          >
            <b-spinner
              v-if="isOptionPosting"
              variant="primary"
              class="d-flex"
              small
            />
            <template v-else>
              {{ $t('Add Another Set of Options') }}
            </template>
          </b-button>
        </b-card-actions>
      </b-overlay>
      <b-overlay
        :show="isFetching"
        spinner-variant="primary"
      >
        <b-card-actions
          ref="card-extras"
          :title="$t('Up-Sell With Extras')"
          action-collapse
          collapsed
        >
          <template v-slot:after-title>
            <feather-icon
              icon="InfoIcon"
              role="button"
              size="20"
              style="vertical-align: top"
              class="ml-25 text-primary toggle-content"
              @click.stop="setIsInfo(['item', 'extras'])"
            />
          </template>
          <template v-slot:default>
            <b-collapse v-model="isInfo.item.extras">
              <i18n
                path="infoExtras"
                tag="small"
                class="d-block mb-2"
              >
                <template v-slot:slot>
                  <br><br>
                </template>
                <template v-slot:slot2>
                  <br><br>
                </template>
              </i18n>
            </b-collapse>
            <validation-observer ref="voExtras">
              <draggable
                :list="extras"
                @change="onChangeDraggableExtra"
              >
                <b-overlay
                  v-for="extra in extras"
                  :key="extra.id"
                  :show="isExtraPosting"
                  spinner-variant="primary"
                >
                  <b-card-actions
                    class="card-inner"
                    no-body
                    action-collapse
                  >
                    <template v-slot:title-and-subtitle>
                      <div
                        class="d-flex align-items-center"
                        style="width: calc(100% - (54px + 20px))"
                      >
                        <icon-move-vertical class="mr-1" />
                        <option-extra-name :value="extra.name">
                          <validation-provider
                            #default="{ errors, failedRules }"
                            name="extra_name"
                            :vid="extra.id"
                            :rules="`max:${textLimitExtra}`"
                          >
                            <b-form-input
                              v-model="extra.name"
                              v-b-tooltip.hover.top="$t('cardExtrasNameTooltip')"
                              :placeholder="$t('Extra Name')"
                              class="input-h input-h5"
                              :state="errors.length ? false : null"
                              @click.stop
                              @blur="onBlurExtraName('voExtras')"
                            />
                            <small class="text-danger">
                              <template v-if="failedRules.max">{{ $t('validationErrorMaxExtraName', { slot: textLimitExtra }) }}</template>
                              <template v-else>{{ errors[0] }}</template>
                            </small>
                          </validation-provider>
                        </option-extra-name>
                      </div>
                    </template>
                    <template v-slot:pre-actions>
                      <feather-icon
                        v-b-tooltip.hover.top="$t('Delete this set of extras')"
                        icon="TrashIcon"
                        size="20"
                        :class="['mr-1', isExtrasValid ? 'text-danger' : null]"
                        role="button"
                        :style="{ opacity: isExtrasValid ? 1 : 0.5 }"
                        @click.stop="onClickDeleteExtra(extra.id)"
                      />
                    </template>
                    <template v-slot:default>
                      <b-card-body>
                        <b-row class="d-flex align-items-center">
                          <b-col cols="auto">
                            <treeselect
                              id="treeselect-extras"
                              :value="getExtraMenuItems(extra.id)"
                              :multiple="true"
                              :disable-branch-nodes="true"
                              :options="optionsTreeselectExtras"
                              :clearable="false"
                              :disabled="!isExtrasValid"
                              :placeholder="$t('Select from existing items')"
                              value-format="object"
                              @input="updateExtraMenuItems($event, extra.id)"
                              @close="onCloseTreeselectExtrasMenuItems"
                            />
                            <tooltip-add-extra target="treeselect-extras" />
                          </b-col>
                          <!--<b-col cols="5">
                            <b-button
                              id="button-add-extra"
                              block
                              variant="outline-primary"
                              :disabled="!isExtrasValid"
                              @click="onClickAddExtraSimpleItem(extra.id)"
                            >
                              {{ $t('Add Simple Item') }}
                            </b-button>
                            <tooltip-add-extra target="button-add-extra" />
                          </b-col>-->
                        </b-row>
                        <div
                          v-if="extra.items.length"
                          class="mt-2"
                        >
                          <draggable
                            :list="extra.items"
                            @change="onChangeDraggableExtraItem"
                          >
                            <b-row
                              v-for="item in extra.items"
                              :key="item.id"
                              class="extra-item-row mb-1"
                            >
                              <template v-if="item.type === 'SimpleItem'">
                                <b-col>
                                  <b-form-group class="mb-0">
                                    <validation-provider
                                      #default="{ errors }"
                                      name="name"
                                      :vid="item.id"
                                      rules="required"
                                    >
                                      <b-form-input
                                        :placeholder="$t('Name')"
                                        :state="errors.length ? false : null"
                                        :value="item.name"
                                        @input="onInputExtraItemParam($event, 'name', extra.id, item.id, 'voExtras')"
                                        @blur="onBlurExtraSimpleItemName('voExtras')"
                                      />
                                      <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                  </b-form-group>
                                </b-col>
                              </template>
                              <template v-else>
                                <b-col
                                  class="d-flex align-items-center"
                                  style="padding-top: 9px"
                                >
                                  <icon-move-vertical class="mr-1" />
                                  <b-link :to="{ name: item.type === 'MenuItem' ? 'menu-item' : 'combination-menu-item', params: { id: item.id } }">
                                    {{ menuItems.find(i => i.id === item.id).name }}
                                  </b-link>
                                </b-col>
                              </template>
                              <b-col
                                cols="auto"
                                class="d-flex mr-2"
                              >
                                <div
                                  v-if="!extra.sameAddPrice"
                                  class="mr-2"
                                >
                                  <b-form-group class="mb-0">
                                    <validation-provider
                                      #default="{ errors }"
                                      name="price"
                                      :vid="item.id + '_price'"
                                      rules="integer_double"
                                      class="d-flex flex-wrap"
                                    >
                                      <div class="d-flex">
                                        <div
                                          class="mr-50"
                                          style="padding-top: 9px"
                                        >
                                          + {{ currencySymbol }}
                                        </div>
                                        <div>
                                          <b-form-input
                                            placeholder="0.00"
                                            :state="errors.length ? false : null"
                                            style="width: 110px"
                                            :value="item.addPrice"
                                            @input="onInputExtraItemParam($event, 'addPrice', extra.id, item.id, 'voExtras')"
                                            @blur="onBlurExtraItemAddPrice"
                                          />
                                          <small class="text-danger">{{ errors[0] }}</small>
                                        </div>
                                      </div>
                                    </validation-provider>
                                  </b-form-group>
                                </div>
                                <div
                                  class="d-flex"
                                  style="padding-top: 9px"
                                >
                                  <icon-stop-list
                                    v-if="item.type === 'SimpleItem'"
                                    v-b-tooltip.hover.top="$t('Display as unavailable')"
                                    :class="[
                                      'd-flex size-20',
                                      {'fill-danger': !item.isEnabled},
                                      {'disabled': !item.isVisible},
                                    ]"
                                    role="button"
                                    @click.native="onClickExtraItemParam('isEnabled', extra.id, item.id)"
                                  />
                                  <feather-icon
                                    v-if="item.type === 'SimpleItem'"
                                    v-b-tooltip.hover.top="$t('Show in menus')"
                                    :icon="item.isVisible ? 'EyeIcon' : 'EyeOffIcon'"
                                    size="20"
                                    class="ml-1 mr-1"
                                    role="button"
                                    @click="onClickExtraItemParam('isVisible', extra.id, item.id)"
                                  />
                                  <feather-icon
                                    icon="XCircleIcon"
                                    size="20"
                                    role="button"
                                    :class="[
                                      { 'feather-disabled': !isExtrasValid },
                                    ]"
                                    @click="onClickDeleteExtraItem(extra.id, item.id)"
                                  />
                                </div>
                              </b-col>
                            </b-row>
                          </draggable>
                        </div>
                        <div class="mt-3">
                          <!--<div class="card-title heading-line heading-line_dark">
                            <span class="heading-line__title">{{ $t('Added Price') }}</span>
                          </div>-->
                          <div class="mb-3">
                            * {{ $t('Does each extra item add the same amount to the total price?') }}
                          </div>
                          <b-form-radio
                            v-model="extra.sameAddPrice"
                            :value="true"
                            @change="onChangeExtraSameAddPrice"
                          >
                            <div class="d-flex flex-wrap">
                              {{ $t('Yes, each extra item adds') }}
                              <b-form-group
                                class="d-inline-flex mb-0 mr-1 ml-1"
                                style="margin-top: -9px"
                              >
                                <validation-provider
                                  #default="{ errors }"
                                  name="price"
                                  :vid="extra.id + '_sameAddPriceValue'"
                                  rules="integer_double"
                                  class="d-flex flex-wrap"
                                >
                                  <div class="d-inline-flex">
                                    <div
                                      class="mr-50"
                                      style="padding-top: 9px"
                                    >
                                      {{ currencySymbol }}
                                    </div>
                                    <div>
                                      <b-form-input
                                        placeholder="0.00"
                                        :state="errors.length ? false : null"
                                        style="width: 110px"
                                        :value="extra.sameAddPriceValue"
                                        @input="onInputExtraSameAddPriceValue($event, extra.id)"
                                        @click="onClickExtraSameAddPriceValue(extra.id)"
                                        @blur="onBlurExtraSameAddPriceValue"
                                      />
                                      <small class="text-danger">{{ errors[0] }}</small>
                                    </div>
                                  </div>
                                </validation-provider>
                              </b-form-group>
                              {{ $t('to the total price') }}
                            </div>
                          </b-form-radio>
                          <b-form-radio
                            v-model="extra.sameAddPrice"
                            :value="false"
                            class="mt-1"
                            @change="onChangeExtraSameAddPrice"
                          >
                            {{ $t('No, each extra item adds a different amount') }}
                          </b-form-radio>
                        </div>
                      </b-card-body>
                    </template>
                  </b-card-actions>
                </b-overlay>
              </draggable>
            </validation-observer>
            <b-button
              size="sm"
              variant="outline-primary"
              :disabled="isExtraPosting || !isExtrasValid"
              @click="onClickAddExtra"
            >
              <b-spinner
                v-if="isExtraPosting"
                variant="primary"
                class="d-flex"
                small
              />
              <template v-else>
                {{ $t('Add Another Set of Extras') }}
              </template>
            </b-button>
          </template>
        </b-card-actions>
      </b-overlay>
      <b-overlay
        :show="isFetching"
        spinner-variant="primary"
      >
        <card-suggestions
          page="item"
          :name="suggestionName"
          :items="suggestionItems"
          :text-limit="textLimitSuggestions"
          @inputName="suggestionName = $event"
          @inputItems="suggestionItems = $event"
          @saveName="onSaveSuggestionName"
          @saveItems="onSaveSuggestionItems"
        />
      </b-overlay>
    </template>
    <modal-confirm-delete
      v-model="isModalConfirmDelete"
      :title="`${$t('itemDeleteTitle')}`"
      :is-message="false"
      @delete="onDelete"
    />
    <modal-preview
      v-model="isModalPreview"
      :link="previewUrl"
      :title="`${$t('modalPreviewItemTitle')}`"
    />
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import TooltipAddExtra from '@/components/tooltip/TooltipAddExtra.vue'
import IconMoveVertical from '@/components/icon/IconMoveVertical.vue'
import OptionExtraName from '@/components/OptionExtraName.vue'

import mixinMenuItem from '@/mixins/menu-item'
import mixinOptions from '@/mixins/options'
import mixinExtras from '@/mixins/extras'

export default {
  name: 'Item',
  components: {
    TooltipAddExtra,
    IconMoveVertical,
    OptionExtraName,
  },
  mixins: [
    mixinMenuItem,
    mixinOptions,
    mixinExtras,
  ],
  methods: {
    async fetch() {
      if (!this.isMenusFetched || !this.isMenuItemsFetched || !this.isBadgesFetched || !this.isTagsFetched) {
        return
      }

      this.isFetching = true

      this.updateHistory()

      const menuItem = this.menuItems.find(i => i.id === this.$route.params.id)
      if (!menuItem) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Error'),
            text: this.$t('Not found'),
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
        await this.$router.push({ name: 'menu-management' })
        return
      }

      const translations = await this.getTranslations(menuItem.id)
      this.setMainData(menuItem)
      this.setOptions(menuItem.options)
      this.setExtras(menuItem.extras)
      await this.setTranslations(translations)

      this.options.forEach(i => {
        this.addOptionItem(i.id, false)
      })
      this.isAddedOptionsItemDefault = true

      this.isFetching = false
    },

    async translateAll() {
      const initialRouteName = this.$route.name
      const initialRouteId = this.$route.params.id
      const initialTranslationActive = this.translationActive

      const response = await this.getGoogleTranslation([
        this.name,
        this.description,
        this.longDescription,
        this.suggestionName,
        ...this.options.flatMap(i => [i.name, ...i.items.map(j => j.name)]),
        ...this.extras.flatMap(i => [i.name, ...i.items.filter(j => j.type === 'SimpleItem').map(j => j.name)]),
        ...this.badges.map(i => i.name),
        ...this.tags.map(i => i.name),
      ])

      if (
        !response
        || initialRouteName !== this.$route.name
        || initialRouteId !== this.$route.params.id
        || initialTranslationActive !== this.translationActive
      ) {
        return
      }

      let index = 3 // suggestionName index in response

      this.translations = this.translations.map(i => {
        if (i.language === this.translationActive) {
          return {
            ...i,
            name: response[0],
            description: response[1],
            longDescription: response[2],
            suggestionName: response[3],
            options: i.options.map(j => {
              index += 1
              const name = response[index]
              const items = j.items.map(k => {
                index += 1
                return { ...k, name: response[index] }
              })
              return { ...j, name, items }
            }),
            extras: i.extras.map(j => {
              index += 1
              const name = response[index]
              const items = j.items.map(k => {
                index += 1
                return { ...k, name: response[index] }
              })
              return { ...j, name, items }
            }),
          }
        }
        return i
      })
      this.patchTranslationActive()

      const refBadges = this.$refs['item-badges-tags-translations_badges']
      refBadges.itemsTranslationsToRender = refBadges.itemsTranslationsToRender.map(i => {
        index += 1
        return { ...i, name: response[index] }
      })
      refBadges.saveItemsTranslations(refBadges.itemsTranslationsToRender)

      const refTags = this.$refs['item-badges-tags-translations_tags']
      refTags.itemsTranslationsToRender = refTags.itemsTranslationsToRender.map(i => {
        index += 1
        return { ...i, name: response[index] }
      })
      refTags.saveItemsTranslations(refTags.itemsTranslationsToRender)
    },
  },
}
</script>

<style lang="sass">
  @import '@/assets/sass/treeselect.sass'

  .option-item-row,
  .extra-item-row
    .icon-move-vertical
      opacity: 0
      cursor: row-resize
    &:hover
      .icon-move-vertical
        opacity: 1
</style>
