<template>
  <div
    v-if="showIfActive || menuState === false"
    class="d-inline-flex align-items-center align-middle"
  >
    <feather-icon
      v-b-tooltip.hover.top="isTooltip ? menuStateCodeOutput : ''"
      icon="TargetIcon"
      :size="size"
      :class="[
        'd-flex',
        menuState === null
          ? null
          : menuState ? 'text-success' : 'text-danger',
      ]"
    />
    <div
      v-if="!isTooltip"
      class="ml-25"
    >
      {{ menuStateCodeOutput }}
    </div>
  </div>
</template>

<script>
import {
  VBTooltip,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'

export default {
  name: 'MenuState',
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    id: {
      type: String,
      required: true,
      default: '',
    },
    isTooltip: {
      type: Boolean,
      required: false,
      default: true,
    },
    size: {
      type: String,
      required: false,
      default: '14',
    },
    showIfActive: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      menusState: 'menuManagement/menusState',
    }),
    menuStateCode() {
      if (!this.menusState.length) {
        return null
      }

      const menu = this.menusState.find(i => i.id === this.id)
      if (!menu) {
        return null
      }

      return menu.code
    },
    menuStateCodeOutput() {
      if (!this.menuStateCode) {
        return ''
      }

      return this.$t(`_menusState.${this.menuStateCode}`)
    },
    menuState() {
      if (!this.menuStateCode) {
        return null
      }

      if (this.menuStateCode === 'ACTIVE') {
        return true
      }
      if (this.menuStateCode === 'INCLUDE_IN_ACTIVE_EVENT') {
        return true
      }
      if (this.menuStateCode === 'INCLUDE_IN_ACTIVE_SCHEDULE') {
        return true
      }

      return false
    },
  },
}
</script>
